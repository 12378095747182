import './polyfills';

import { contactSubmit } from './contact.js';
import { headerInit } from './header.js';
import { refferalInit } from './referral.js';
import { initRatesMobile, initRatesCaroMap, initRatesTabs, initRatesFilters, initRatesCoupon } from './facility';
import { tipInit } from './tips.js';
import { locationMapInit } from './locations.js';

headerInit();

if(document.querySelector('#toggle_fac_info')) {
	initRatesMobile();
}

if(document.querySelector('#facility_info_map_caro_wrap')) {
	initRatesCaroMap();
}

if(document.querySelector('#facility_tabs_wrap')) {
	initRatesTabs();
}

if(document.querySelector('#facility_filters')) {
	initRatesFilters();
}

if(document.querySelector('#facility_coupon_overlay')) {
	initRatesCoupon();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('third_form')) {
	contactSubmit({
		elementSelector: '#third_form',
		required: ['name', 'email', 'phone', 'message']
	});
}

if (document.getElementById('tips_container')) {
	tipInit();
}

if (document.getElementById('locations_map')) {
	locationMapInit();
}

if (document.getElementById('referral_form')) {
	refferalInit();
}
