/* global google */

import { AutomatitCarousel } from './automatit_carousel.m';
import { contactSubmit } from './contact.js';
import { getMapsHelper } from './util/mapsHelper';

const ratesInfoMobile = {
	button: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_info_wrapper'),
	btnClick: function() {
		this.button.setAttribute('state', (this.button.getAttribute('state') === 'open' ? 'closed' : 'open'));
		this.info.setAttribute('state', (this.info.getAttribute('state') === 'open' ? 'closed' : 'open'));
	},
	init: function() {
		this.button.addEventListener('click', () => this.btnClick());
	}
};

const ratesCaroMap = {
	thumbsContainer: document.querySelector('#facility_caro_thumbs'),
	thumbs: document.querySelectorAll('#facility_caro_thumbs button'),
	controlsContainer: document.querySelector('#facility_caro_map_controls_buttons'),
	controls: document.querySelectorAll('#facility_caro_map_controls_buttons button'),
	theCaro: null,
	theMap: {
		el: document.querySelector('#facility_map'),
		instance: null
	},
	initMap: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.el,
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="" /></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(15);
		});
	},
	controlsClick: function(e) {
		const clickedButton = e.target.closest('button');
		if(clickedButton) {
			this.controls.forEach(control => control.removeAttribute('active'));
			clickedButton.setAttribute('active', true);

			const activeButton = document.querySelector('#facility_caro_map_controls_buttons button[active]');

			if(activeButton.hasAttribute('map')) {
				this.theMap.el.setAttribute('show', true);
			} else {
				this.theMap.el.removeAttribute('show');
			}
		}
	},
	thumbClick: function(e) {
		const clickedButton = e.target.closest('button');
		if(clickedButton) {
			this.theCaro.slideTo(clickedButton.getAttribute('imgNum'));
		}
	},
	init: function() {
		let rotation = (window.facCaroImgs.length > 1) ? 5000 : false;
		this.theCaro = AutomatitCarousel({
			element: document.querySelector('#facility_caro'),
			intervalTiming: rotation,
			images: window.facCaroImgs,
			imagesAsBackgrounds: true,
			useChevrons: false,
			beforeSlide: (p,n) => {
				this.thumbs.forEach(thumb => thumb.setAttribute('active', 'no'));
				document.querySelector(`#facility_caro_thumbs button[imgNum="${n.dataset.slide}"]`).setAttribute('active', 'yes');
			}
		});
		this.initMap();
		this.thumbsContainer.addEventListener('click', e => this.thumbClick(e));
		this.controlsContainer.addEventListener('click', e => this.controlsClick(e));
	}
};

const ratesTabs = {
	tabContainer: document.querySelector('#facility_tabs_wrap'),
	tabButtons: document.querySelectorAll('#facility_tabs_wrap button'),
	tabs: document.querySelectorAll('.facility_tab'),
	tabClick: function(e) {
		const clickedTab = e.target.closest('button');
		
		if(clickedTab) {
			this.tabButtons.forEach(btn => btn.removeAttribute('active'));
			clickedTab.setAttribute('active', true);

			const activeTab = document.querySelector('#facility_tabs_wrap button[active]');

			if(activeTab) {
				this.tabs.forEach(tab => tab.removeAttribute('active'));
				document.querySelector(`.facility_tab[tab="${activeTab.getAttribute('tab')}"]`).setAttribute('active', true);
			}
		}
	},
	init: function() {
		this.tabContainer.addEventListener('click', e => this.tabClick(e));
	}
};

const ratesFilters = {
	filterContainer: document.querySelector('#facility_filters'),
	filters: document.querySelectorAll('#facility_filters button'),
	rows: document.querySelectorAll('.rates_row'),
	noUnits: document.querySelector('#no_units'),
	filterClick: function(e) {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedFilter.setAttribute('active', true);

			const activeFilter = document.querySelector('#facility_filters button[active]');

			let numRates = 0;

			this.rows.forEach(row => {
				row.setAttribute('hide', true);

				if(row.getAttribute('size') === activeFilter.getAttribute('size') || activeFilter.getAttribute('size') === 'all') {
					row.removeAttribute('hide');
					numRates++;
				}
			});

			if(!numRates) {
				this.noUnits.style.display = 'block';
			} else {
				this.noUnits.style.display = 'none';
			}
		}
	},
	init: function() {
		this.filterContainer.addEventListener('click', e => this.filterClick(e));
	}
};

const ratesCoupon = {
	claimButton: document.querySelector('#open_coupon_form'),
	overlay: document.querySelector('#facility_coupon_overlay'),
	showForm: function() {
		this.overlay.setAttribute('show', true);
	},
	overlayClick: function(e) {
		if(e.target.id === "facility_coupon_overlay" || e.target.id === "close_coupon_box") {
			this.overlay.removeAttribute('show');
		}
	},
	init: function() {
		contactSubmit({
			elementSelector: '#coupon_form',
			required: ['name', 'email', 'address', 'phone']
		});
		this.claimButton.addEventListener('click', () => this.showForm());
		this.overlay.addEventListener('click', e => this.overlayClick(e));
	}
};

export const initRatesMobile = () => ratesInfoMobile.init();
export const initRatesCaroMap = () => ratesCaroMap.init();
export const initRatesTabs = () => ratesTabs.init();
export const initRatesFilters = () => ratesFilters.init();
export const initRatesCoupon = () => ratesCoupon.init();