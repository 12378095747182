/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

let locationMap = {
	map: document.getElementById('locations_map'),
	container: document.getElementById('locations_results'),
	internalHeader: document.querySelector('.internal_header'),
	moveMap: function () {
		if (window.innerWidth > 750) {
			let yScroll = window.scrollY - this.internalHeader.offsetHeight;
			let containerHeight = this.container.offsetHeight;
			let mapHeight = this.map.offsetHeight;
			if (yScroll > containerHeight - mapHeight) {
				yScroll = containerHeight - mapHeight;
			}
			if (yScroll < 0) {
				yScroll = 0;
			}
			this.map.style.transform = `translateY(${yScroll}px)`;
		} else {
			this.map.style.transform = `translateY(0px)`;
		}
	},
	startMap: function () {
		const mapHelper = getMapsHelper();

		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: document.getElementById('locations_map'),
					locationElementSelector: '.map_source',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                            <a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
                                <img src="/dist/images/icons/map_pin.svg" alt="Visit facility" />
                            </a>
                            `
						};
					},
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if (document.querySelectorAll('.map_source').length < 2) {
						theMap.setZoom(15);
					}
					[].forEach.call(document.querySelectorAll('.map_source'), i => {
						const markerData = mapHelper.getMarker(i);
						markerData.element.addEventListener('mouseenter', () => {
							i.classList.add('hover');
						});
						markerData.element.addEventListener('mouseleave', () => {
							i.classList.remove('hover');
						});
					});
				});
			});
	},
	init: function () {
		document.addEventListener('scroll', () => {
			this.moveMap();
		});
		this.startMap();
	}
};

export const locationMapInit = () => {
	locationMap.init();
};