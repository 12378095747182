let tips = {
	nav: document.getElementById('tips_nav'),
	panels: document.querySelectorAll('.tip_panel'),
	toggleTip: function (toggle) {
		if (toggle.classList.contains('tip_toggle')) {
			this.nav.querySelector('.active').classList.remove('active');
			[].forEach.call(this.panels, function (el) {
				el.classList.remove('active');
			});

			toggle.classList.add('active');
			document.querySelector(`.tip_panel.${toggle.dataset.tip}`).classList.add('active');
		}
	},
	init: function () {
		this.nav.addEventListener('click', (e) => {
			this.toggleTip(e.target);
		});
	}
};

export const tipInit = () => {
	tips.init();
};