let headerNav = {
	control: document.getElementById('header_menu_toggle'),
	element: document.getElementById('header_content'),
	toggle: function () {
		this.control.classList.toggle('open');
		this.element.classList.toggle('open');
	},
	init: function () {
		this.control.addEventListener('click', () => {
			this.toggle();
		});
	}
};

export const headerInit = () => {
	headerNav.init();
};