import { contactSubmit } from './contact.js';

export const refferalInit = () => {
	contactSubmit({
		elementSelector: '#referral_form',
		required: ['name', 'referred_phone', 'referred_email', 'facility'],
		errorSubstitutions: {
			referred_phone: "phone",
			referred_email: "email",
		},
		successOverride: 'Thank you for completing our referral program form. We will be in contact with your referred contact and apply a credit to your account upon completion of a rental.'
	});
};