let submitInProgress = false;

const substituteErrors = (errs, subs) => {
	console.log(Object.keys(subs));
	if(Object.keys(subs).length) {
		return errs.map(err => {
			if(subs[err] !== undefined) {
				return subs[err];
			} else {
				return err;
			}
		});
	}
	return errs;
};

const updateResponseMessage = (errors, subs) => {
	errors = substituteErrors(errors, subs);
	let response;
	if(errors.length === 1){
		response = `The ${errors[0]} field is required.`;
	}else if(errors.length === 2){
		response = `The ${errors.join(' and ')} fields are required`;
	}else{
		errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
		response = `The ${errors.join(', ')} fields are required`;
	}
	return response;
	// document.querySelector('.response_message').innerHTML = "<span class='error'>"+response+"</span>";
};

/**
 * 
 * @param {Object} param0 Object of named parameters
 * 
 * elementSelector : the css selector for the form element
 * required : (String[]) 
 */
const _listenToForm = ({
	elementSelector = '#contact_form',
	required = ['name', 'email', 'phone', 'facility', 'message'],
	errorSubstitutions = {},
	successOverride = null
} = {}) => {
    
	let captcha_token_field = document.querySelector('#recaptcha_token_input');
	if (captcha_token_field) {
		// eslint-disable-next-line
		grecaptcha.ready(function () {
			// eslint-disable-next-line
			grecaptcha.execute('6LcVWFEfAAAAAIQIzIikDHRCwZmv4Iv7No_qB6xa', {action: 'submit'}).then(function(token) {
				captcha_token_field.value = token;
			});
		});
	}

	document.querySelector(elementSelector).addEventListener('submit', function(e) {
		e.stopImmediatePropagation();
		e.preventDefault();
		if (document.getElementById('hp').value !== '') return;
		const formEl = document.querySelector(elementSelector);
		Array.prototype.forEach.call(formEl.querySelectorAll('.error'), function(error) {
			error.classList.remove('error');
		});
		formEl.querySelector('.response_message').innerHTML = '';
		
		if(captcha_token_field && !captcha_token_field.value.length) {
			formEl.querySelector('.response_message').innerHTML = "<span class='error'>reCAPTCHA verification failed. Please give us a call if you have any questions.</span>";
			return;
		}

		if(submitInProgress) {
			return;
		}
	
		// var checkFields = new Array('name', 'email', 'message');
		let errorsFound = new Array();
		required.forEach(function(item){
			if(document.getElementById(item+'_input').value === ''){
				document.getElementById(item+'_input').classList.add('error');
				errorsFound.push(item);
			}
		});
		if(!submitInProgress && errorsFound.length === 0){
			submitInProgress = true;
            
			let request = new XMLHttpRequest(),
				method = 'POST',
				url = formEl.getAttribute('action'),
				data = new FormData(formEl);
	
			request.onreadystatechange = function() {
				if(request.readyState === 4 && request.status === 200) {
					let response = JSON.parse(request.responseText);
					console.log(response);
					if(response.ret_det.success){
						let input = formEl.querySelector('#contact_form_submit');
						input.parentElement.removeChild(input);
						formEl.querySelector('.response_message').innerHTML = `<span class='success'>${successOverride || response.ret_det.message}</span>`;
					}else{
						if(typeof response.data.error_fields !== 'undefined'){
							let errors = [];
							if(response.data.error_fields.length === 1 && response.data.error_fields[0].field_name === 'recaptcha_token') {
								formEl.querySelector('.response_message').innerHTML = "<span class='error'>reCAPTCHA verification failed. Please give us a call if you have any questions.</span>";
							} else {
								for(let x = 0; x < response.data.error_fields.length; x++){
									if (response.data.error_fields[x].field_name !== 'recaptcha_token') {
										errors.push(response.data.error_fields[x].field_name);
									}
								}
								formEl.querySelector('.response_message').innerHTML = "<span class='error'>"+updateResponseMessage(errors);+"</span>";
							}
						}else{
							formEl.querySelector('.response_message').innerHTML = "<span class='error'>"+response.ret_det.message+"</span>";
						}
					}
					submitInProgress = false;
				}
			};
			request.open(method, url, true);
			request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
			request.send(data);
		}
		else if(errorsFound.length > 0){
			formEl.querySelector('.response_message').innerHTML = `<span class='error'>${updateResponseMessage(errorsFound, errorSubstitutions)}</span>`;
		}
		else{
			formEl.querySelector('.response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
		}
	});
};

export const contactSubmit = _listenToForm;
